:host, :root {
  --fa-font-solid: normal 900 1em / 1 "Font Awesome 6 Free";
}

@font-face {
  font-family: "Font Awesome 6 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("fa-solid-900.0e0ea955.woff2") format("woff2"), url("fa-solid-900.6fd53b4d.ttf") format("truetype");
}

.fa-solid, .fas {
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
}

/*# sourceMappingURL=index.403ff9cd.css.map */
